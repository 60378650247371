/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {checkEmail, getUserByToken, googleAuth, register} from '../core/_requests'
import {Link, useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import {useGoogleLogin} from "@react-oauth/google";

const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    changepassword: '',
    acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 characters')
        .max(50, 'Maximum 50 characters')
        .required('Email is required')
        .test('Unique Email', 'This email is already in use', (value) => {
            return checkEmail(value);
        }),
    firstName: Yup.string()
        .min(3, 'Minimum 3 characters')
        .max(50, 'Maximum 50 characters')
        .required('First name is required'),
    lastName: Yup.string()
        .min(3, 'Minimum 3 characters')
        .max(50, 'Maximum 50 characters')
        .required('Last name is required'),
    password: Yup.string()
        .min(8, 'Minimum 8 characters')
        .max(50, 'Maximum 50 characters')
        .required('Password is required'),
    changepassword: Yup.string()
        .min(8, 'Minimum 8 characters')
        .max(50, 'Maximum 50 characters')
        .required('Password confirmation is required')
        .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
    const [loading, setLoading] = useState(false)
    const [googleLoading, setGoogleLoading] = useState(false);
    const [googleAuthError, setGoogleAuthError] = useState<string>();
    const {saveAuth, setCurrentUser} = useAuth()
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues,
        validationSchema: registrationSchema,
        // validateOnChange: false,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            try {
                const {data: auth} = await register(
                    values.firstName,
                    values.lastName,
                    values.email,
                    values.password,
                    values.changepassword
                )
                saveAuth(auth)
                const {data: user} = await getUserByToken(auth.token);
                setCurrentUser(user);
                navigate('/message/verify');
            } catch (error) {
                console.error(error)
                saveAuth(undefined)
                setStatus('The registration details is incorrect')
                setSubmitting(false)
                setLoading(false)
            }
        },
    })

    const googleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            setGoogleLoading(true);
            const auth = await googleAuth(tokenResponse.access_token)
                .then(response => response.data)
                .catch(() => {
                    setGoogleAuthError('Fail to login with Google');
                    setGoogleLoading(false);
                    return Promise.reject();
                });
            saveAuth(auth);
            navigate('/message/welcome');
        },
        onError: errorResponse => {
            saveAuth(undefined)
            setGoogleAuthError('Fail to login with Google')
            setGoogleLoading(false)
        },
    });

    useEffect(() => {
        PasswordMeterComponent.bootstrap()
    }, [])

    return (
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
        >
            <div className='text-center mb-11'>
                <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
            </div>

            {googleLoading ? (
                <div className="text-center">
                    <span className="spinner-border align-middle"></span>
                </div>
            ) : (
                <>
                    <div className='row g-3 mb-9'>
                        <div className='offset-md-3 col-md-6'>
                            <button
                                type={'button'}
                                className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
                                onClick={() => googleLogin()}
                            >
                                <img
                                    alt='Logo'
                                    src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                                    className='h-15px me-3'
                                />
                                Sign in with Google
                            </button>
                        </div>
                    </div>

                    <div className='separator separator-content my-14'>
                        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
                    </div>

                    {googleAuthError && (
                        <div className='mb-lg-15 alert alert-danger'>
                            <div className='alert-text font-weight-bold'>{googleAuthError}</div>
                        </div>
                    )}

                    {formik.status && (
                        <div className='mb-lg-15 alert alert-danger'>
                            <div className='alert-text font-weight-bold'>{formik.status}</div>
                        </div>
                    )}

                    <div className='fv-row mb-8'>
                        <label className='form-label fw-bolder text-dark fs-6'>Name</label>
                        <div className='d-flex'>
                        <div className='p-2'>
                        <input
                            // placeholder='First Name'
                            type='text'
                            autoComplete='off'
                            {...formik.getFieldProps('firstName')}
                            className={clsx(
                                'form-control bg-transparent',
                                {
                                    'is-invalid': formik.touched.firstName && formik.errors.firstName,
                                },
                                {
                                    'is-valid': formik.touched.firstName && !formik.errors.firstName,
                                }
                            )}
                        />
                        {formik.touched.firstName && formik.errors.firstName && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.firstName}</span>
                                </div>
                            </div>
                        )}
                            <span className='text-muted'>First Name</span>
                        </div>
                        <div className='p-2'>
                        <input
                            // placeholder='Last Name'
                            type='text'
                            autoComplete='off'
                            {...formik.getFieldProps('lastName')}
                            className={clsx(
                                'form-control bg-transparent',
                                {
                                    'is-invalid': formik.touched.lastName && formik.errors.lastName,
                                },
                                {
                                    'is-valid': formik.touched.lastName && !formik.errors.lastName,
                                }
                            )}
                        />
                        {formik.touched.lastName && formik.errors.lastName && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.lastName}</span>
                                </div>
                            </div>
                        )}
                        <span className='text-muted'>Last Name</span>
                        </div>
                        </div>
                       
                    </div>
                    <div className='fv-row mb-8'>
                        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
                        <input
                            placeholder='Email'
                            type='email'
                            autoComplete='off'
                            {...formik.getFieldProps('email')}
                            className={clsx(
                                'form-control bg-transparent',
                                {'is-invalid': formik.touched.email && formik.errors.email},
                                {
                                    'is-valid': formik.touched.email && !formik.errors.email,
                                }
                            )}
                        />
                        {formik.touched.email && formik.errors.email && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.email}</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className='fv-row mb-8' data-kt-password-meter='true'>
                        <div className='mb-1'>
                            <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                            <div className='position-relative mb-3'>
                                <input
                                    type='password'
                                    placeholder='Password'
                                    autoComplete='off'
                                    {...formik.getFieldProps('password')}
                                    className={clsx(
                                        'form-control bg-transparent',
                                        {
                                            'is-invalid': formik.touched.password && formik.errors.password,
                                        },
                                        {
                                            'is-valid': formik.touched.password && !formik.errors.password,
                                        }
                                    )}
                                />
                                {formik.touched.password && formik.errors.password && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.password}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div
                                className='d-flex align-items-center mb-3'
                                data-kt-password-meter-control='highlight'
                            >
                                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                            </div>
                        </div>
                        <div className='text-muted'>
                            Use 8 or more characters with a mix of letters, numbers & symbols.
                        </div>
                    </div>

                    <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
                        <input
                            type='password'
                            placeholder='Password confirmation'
                            autoComplete='off'
                            {...formik.getFieldProps('changepassword')}
                            className={clsx(
                                'form-control bg-transparent',
                                {
                                    'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                                },
                                {
                                    'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                                }
                            )}
                        />
                        {formik.touched.changepassword && formik.errors.changepassword && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.changepassword}</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className='fv-row mb-8'>
                        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                id='kt_login_toc_agree'
                                {...formik.getFieldProps('acceptTerms')}
                            />
                            <span>
            I Accept the{' '}
                                <a
                                    href='https://keenthemes.com/metronic/?page=faq'
                                    target='_blank'
                                    className='ms-1 link-primary'
                                >
              Terms
            </a>
            .
          </span>
                        </label>
                        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.acceptTerms}</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className='text-center'>
                        <button
                            type='submit'
                            id='kt_sign_up_submit'
                            className='btn btn-lg btn-primary w-100 mb-5'
                            disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
                        >
                            {!loading && <span className='indicator-label'>Submit</span>}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                        <Link to='/auth/login'>
                            <button
                                type='button'
                                id='kt_login_signup_form_cancel_button'
                                className='btn btn-lg btn-light w-100 mb-5'
                            >
                                Cancel
                            </button>
                        </Link>
                    </div>
                </>
            )}
        </form>
    )
}
