import  React,{useState, useEffect } from 'react'
import { toAbsoluteUrl } from '../../helpers';
import { sendContactMessage } from '../../../app/modules/auth/core/_requests';
import Swal from 'sweetalert2';
// import axios from "axios";
// import authService from '../Services/auth.service'

const ContactUs = () => {
    const reg = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/;

    const [isSubmit, setIsSubmit] = useState(false);
    const [contactFormInputes, setcontactFormInputes] = useState({
        cotactUserName: "",
        contactMessage: "",
        contactEmail: "",
    })
    const [errorContactForm, setErrorContactForm] = useState({
        cotactUserName: "",
        contactMessage: "",
        contactEmail: "",
    })
    const handlecontactusFormInputs = (e) => {
        const { name, value } = e.target
        setcontactFormInputes({ ...contactFormInputes, [name]: value })
        switch (name) {
            case 'cotactUserName':
                errorContactForm.cotactUserName = value.length > 0 ? "" : "Enter name";
                break;
            case 'contactEmail':
                errorContactForm.contactEmail = reg.test(value) ? "" : "Enter Valid email";
                break;

        }
        setErrorContactForm(errorContactForm)
    }

    const validate = (values) => {
        if (!contactFormInputes.cotactUserName) {
            errorContactForm.cotactUserName = "Enter name";
        }
        if (!contactFormInputes.contactEmail) {
            errorContactForm.contactEmail = "Enter email";
        }

        return errorContactForm;
    };

    useEffect(() => {
        if (Object.keys(errorContactForm).length === 0 && isSubmit) {
            console.log(contactFormInputes);
        }
    }, []);
    const submitContactForm = (e) => {
        e.preventDefault();
        setErrorContactForm(validate(contactFormInputes));
        setIsSubmit(true);

        const messagePayload = {
            name: contactFormInputes.cotactUserName,
            email: contactFormInputes.contactEmail,
            message: contactFormInputes.contactMessage,
        }

        if (contactFormInputes.contactEmail !== "" && contactFormInputes.cotactUserName !== "") {
            sendContactMessage(messagePayload).then((response) => {
                console.log("the response"+response);
                Swal.fire({
                    title: "Message Sent",
                    text: "Thank you for contacting DeFinance.",
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: "CLOSE",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    }
                }).then(()=>{
                    setcontactFormInputes({
                        cotactUserName: "",
                        contactMessage: "",
                        contactEmail: "",
                    });
                    const closeBtn = document.getElementById('contact_close_btn');
                    if (closeBtn) {
                        closeBtn.click();
                    }                  
                })
            }).catch(error=>{
                console.log(error);
                Swal.fire({
                    title: "Error",
                    text: "Sorry, something went wrong. Kindly try again later.",
                    icon: "error",
                }).then(()=>{
                    setcontactFormInputes({
                        cotactUserName: "",
                        contactMessage: "",
                        contactEmail: "",
                    });
                    const closeBtn = document.getElementById('contact_close_btn');
                    if (closeBtn) {
                        closeBtn.click();
                    }                   
                })
            })
            setcontactFormInputes({
                cotactUserName: "",
                contactMessage: "",
                contactEmail: "",
            })
        }

    }

    const currentTheme = document.documentElement.getAttribute('data-bs-theme');

    return (
        <>
                <div className="modal fade modal-xl" id="modal_contact_us" role="dialog" aria-hidden="true" data-bs-focus="false">
                    <div className="modal-dialog modal-dialog-centered ">
                        <div className="modal-content p-10 border border-light border-1 rounded-3" style={{backgroundColor: "#13263c"}} >
                        <div className="d-flex justify-content-end">
                        <div className="btn btn-sm btn-icon btn-color-white" data-bs-dismiss="modal" id="contact_close_btn" 
                                            >
                                            <i className="ki-duotone ki-cross fs-1">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                            </i>
                                </div>

                        </div>
                        <div className="contact_inner d-flex flex-column-reverse flex-lg-row" >
                        <div className={`contact_box pe-20 pt-8 ps-20 pb-10 w-100 mw-100 border border-light border-1 rounded ${currentTheme==='light'&& 'bg-white'}`} style={{backgroundColor:"#151521"}} >
                                <h1 className='mb-5 fw-bolder mb-5 '>Contact Us</h1>
                                <div className='custom_input mb-11'>
                                    <div className="input_wrap ">
                                        <label htmlFor="name" className=' fw-bold fs-2 mb-2'>Name</label>
                                        <input type="text" name="cotactUserName" id="cotactUserName" className="form-control border-bottom-1 border-top-0 border-left-0 border-right-0 border-dark rounded-0" value={contactFormInputes.cotactUserName} onChange={handlecontactusFormInputs} />
                                    </div>
                                    <span className="red-error contact">{errorContactForm.cotactUserName}</span>
                                </div>
                                <div className='custom_input mb-11'>
                                    <div className="input_wrap ">
                                        <label htmlFor="email " className=' fw-bold fs-2 mb-2'>Email</label>
                                        <input type="email" name='contactEmail' id="contactEmail" className="form-control border-bottom-1 border-top-0 border-left-0 border-right-0 border-dark rounded-0" value={contactFormInputes.contactEmail} onChange={handlecontactusFormInputs} />
                                    </div>
                                    <span className="red-error contact">{errorContactForm.contactEmail}</span>
                                </div>
                                <div className='custom_input mb-11'>
                                    <div className="input_wrap ">
                                        <label htmlFor="Message " className=' fw-bold fs-2 mb-2'>Message</label>
                                        <textarea name="contactMessage" id="contactMessage"  className="form-control bg-secondary min-h-100px " value={contactFormInputes.contactMessage} onChange={handlecontactusFormInputs}></textarea>
                                    </div>
                                </div>
                                <div className='text-center'>
                                <button className="btn send_message flex_align btn btn-primary max-w-247px h-60px fs-1 text-dark" onClick={submitContactForm}>Send Message</button>
                                </div>
                            </div>
                            <div className="contact_img w-100">
                            <img src={toAbsoluteUrl("/media/auth/contact_img.svg")}  alt="ContactImg" className='mw-100'/>
                        </div>
                        
                        </div>
                            </div>
                    </div>
                    </div>

        </>
    )
}

export default ContactUs