import { createSlice } from '@reduxjs/toolkit';

export const documentSlice= createSlice({
    name: 'document',
    initialState: {
        currentDocument: null,
    },
    reducers: {
        setCurrentDocument: (state, action) => {
            state.currentDocument = action.payload;
        },
    }
});

export const { setCurrentDocument } = documentSlice.actions;

export default documentSlice.reducer;
