const currentTheme = document.documentElement.getAttribute('data-bs-theme')

const PaymentFormAppearance = 
    currentTheme === 'light' ?
    {   
        variables: {
            fontFamily: 'Sohne, system-ui, sans-serif',
            fontWeightNormal: '500',
            borderRadius: '8px',
            colorBackground: '#f9f9f9',
            colorPrimary: '#77cad2fa',
            colorPrimaryText: 'grey',
            colorText: 'black',
            colorTextSecondary: 'grey',
            colorTextPlaceholder: '#9aa1b7',
            colorIconTab: 'white',
            colorLogo: 'dark',
        }
    } : {
        variables: {
        fontFamily: 'Sohne, system-ui, sans-serif',
        fontWeightNormal: '500',
        borderRadius: '8px',
        colorBackground: '#f9f9f9',
        colorPrimary: '#77cad2fa',
        colorPrimaryText: 'grey',
        colorText: '#cdcdde',
        colorTextSecondary: 'grey',
        colorTextPlaceholder: '#9aa1b7',
        colorIconTab: 'white',
        colorLogo: 'dark',
    }
}
export default PaymentFormAppearance;