import { Route, Routes } from 'react-router-dom';
import { Registration } from './components/Registration';
import { ForgotPassword } from './components/ForgotPassword';
import { ResetPassword } from './components/ResetPassword';
import { Login } from './components/Login';
import { AuthLayout } from './AuthLayout';
import { SecondaryRegistration } from './components/SecondaryRegistration';

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='secondary-registration/:token/:email/:broker' element={<SecondaryRegistration />} />
      <Route path='reset-password/:token/:email/:broker' element={<ResetPassword />} />
      <Route path='reset-password/:token/:email' element={<ResetPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
