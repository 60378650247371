import { FC } from "react";
import {toAbsoluteUrl} from "../../../../_metronic/helpers";
import { Link } from "react-router-dom";

type ReadyProps = {
  resetFormData: () => void
}

const Ready: FC<ReadyProps> = ({resetFormData}) => {
    return (
        <div className="w-100">
            <div className="pb-12 text-center">
                <h1 className="fw-bold text-dark">Project Created!</h1>
                <div className="text-muted fw-semibold fs-4">We are looking over your requirements and will be in touch soon.</div>
            </div>
            <div className="d-flex flex-center pb-20">
                <button type="button" className="btn btn-lg btn-light me-3" data-kt-element="complete-start" 
                onClick={resetFormData}>Create Another Project</button>
                <Link 
                  to="/dashboard" 
                >
                   <button 
                      className="btn btn-lg btn-primary"
                      data-bs-toggle="tooltip"  
                      data-bs-dismiss="modal"
                      title="Project Dashboard"
                      onClick={()=>{window.location.reload()}}
                    > 
                     Project Dashboard
                    </button>
                </Link>
            </div>
            <div className="text-center px-4">
                <img src={toAbsoluteUrl('/media/illustrations/unitedpalms-1/17.png')} alt="" className="mww-100 mh-350px" />
            </div>
        </div>
    );
}

export default Ready