import {ErrorMessage, Field} from "formik";
import {ProjectSubType} from "../core/_models";
import React from "react";
import {useSelector} from "react-redux";

type TypeItemProps = {
    type_id_value: number | undefined;
    type: ProjectSubType;
    submitForm: () => void;
};

type TypeProps = {
    type_id_value: number | undefined;
    submitForm: () => void;
}

const TypeItem = ({ type_id_value, type, submitForm }: TypeItemProps) => {
    return (
        <>
            <Field
                type='radio'
                className='btn-check'
                name='type_id'
                value={type.id}
                onClick={submitForm}
                id={`create_project_form_sub_type_${type.id}`}
            />
            <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6 mb-6 ${type_id_value == type.id && 'active'}`} htmlFor={`create_project_form_sub_type_${type.id}`}>
                <span className="d-flex">
                    <i className={`${type.icon} fs-3x me-5`}></i>
                    <span className="ms-4">
                        <span className="fs-3 fw-bold text-gray-900 mb-2 d-block">{type.name}</span>
                        <span className="fw-semibold fs-4 text-muted">{type.description}</span>
                    </span>
                </span>
            </label>
        </>
    );
}

export default ({ type_id_value, submitForm }: TypeProps) => {
    const subTypes = useSelector((state: any) => state.project.subTypes);

    return (
        // @ts-ignore
        <div className='w-100'>
            <div className="pb-7 pb-lg-12">
                <h1 className="fw-bold text-dark">Choose Project Type</h1>
            </div>
            {subTypes?.length > 0 && (
                <div className="fv-row mb-15" data-kt-buttons="true">
                    {subTypes.map((type: ProjectSubType, index: number) => <TypeItem type_id_value={type_id_value} type={type} key={index} submitForm={submitForm}/>)}
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='type_id'/>
                    </div>
                </div>
            )}
        </div>
    )
}