import axios from "axios";
import {reverse} from "lodash";
import { Member } from "../../pages/project/core/_models";

const API_URL = process.env.REACT_APP_API_URL;
const BASE_URL = `${API_URL}/chats`;

export interface ConversationData {
    project_id: number;
    title: string;
    description: string;
    logo: string;
}

export interface Sender {
    id: number;
    name: string;
    email: string;
    role: "customer" | "contractor" | "manager";
    avatar: string;
}

export interface Conversation {
    id: number;
    private: boolean;
    direct_message: boolean;
    data: ConversationData;
    created_at: string;
    updated_at: string;
}

export interface Message {
    id: number;
    body: string;
    sender: Sender | null;
    is_sender: boolean;
    type: string;
    data: object;
    conversation_id: number;
    created_at: string;
    updated_at: string;
}

export interface PaginatedResult<T> {
    items: T[];
    pagination: PaginationDataSet;
}

export interface PaginationDataSet {
    total: number;
    current_page: number;
    last_page: number;
}

export function getPaginationSet(data: any): PaginationDataSet {
    return {
        total: data.total,
        current_page: data.current_page,
        last_page: data.last_page,
    };
}

export const rawDataToConversationObject = (data: any): Conversation => ({
    id: data.id,
    private: data.private,
    direct_message: data.direct_message,
    data: JSON.parse(data.data),
    created_at: data.created_at,
    updated_at: data.updated_at,
});

export const rawDataToConversationObject2 = (data: any): Conversation => ({
    id: data.id,
    private: data.private,
    direct_message: data.direct_message,
    data: data.data,
    created_at: data.created_at,
    updated_at: data.updated_at,
});

export const rawDataToMessageObject = (data: any, auth_id?: number): Message => ({
    id: data.id,
    body: data.body,
    sender: data.sender,
    // @ts-ignore
    is_sender: data.sender ? data.sender.id === auth_id : false,
    type: data.type,
    data: data.data,
    conversation_id: data.conversation_id,
    created_at: data.created_at,
    updated_at: data.updated_at,
});

export const getConversations = (): Promise<PaginatedResult<Conversation>> => {
    const urlParams = new URLSearchParams(window.location.search);
    const new_internal = urlParams.get('new_internal');
    const conversation_id = urlParams.get('conversation_id');

    return new Promise((resolve, reject) => {
        axios
            .get(`${BASE_URL}/conversations`, {
                params: {
                    new_internal: new_internal,
                    conversation_id: conversation_id
                }
            })
            .then(({data}) => {
                resolve({
                    items: (data.data || []).map((datum: any) =>
                        rawDataToConversationObject(datum)
                    ),
                    pagination: getPaginationSet(data.meta),
                });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getConversation = (
    project_id: number | string
): Promise<Conversation> => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${BASE_URL}/conversations/${project_id}/get`)
            .then(({data}) => {
                resolve(rawDataToConversationObject2(data));
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getParticipants = (conversation_id: number): Promise<Sender[]> => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${BASE_URL}/conversations/${conversation_id}/participants`)
            .then(({data}) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getMessages = (
    conversation_id: number,
    page: number,
    auth_id?: number,
): Promise<PaginatedResult<Message>> => {
    return new Promise((resolve, reject) => {
        const url = page
            ? `${BASE_URL}/conversations/${conversation_id}/messages?page=${page}`
            : `${BASE_URL}/conversations/${conversation_id}/messages`;

        axios
            .get(url)
            .then(({data}) => {
                resolve({
                    items: reverse(data.data || []).map((datum: any) =>
                        rawDataToMessageObject(datum, auth_id)
                    ),
                    pagination: getPaginationSet(data.meta),
                });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const unreadMessages = (conversation_id: number): Promise<number> => {
    return new Promise((resolve, reject) => {
        axios.get(`${BASE_URL}/conversations/${conversation_id}/unread`)
            .then(({data}) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const allUnreadMessages = (): Promise<number> => {
    return new Promise((resolve, reject) => {
        axios.get(`${BASE_URL}/conversations/all_unread`)
            .then(({data}) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const markAsRead = (conversation_id: number): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${BASE_URL}/conversations/as_read`, {conversation_id})
            .then(({data}) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const deleteMessage = (message_id: number): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${BASE_URL}/conversations/delete`, {message_id})
            .then(({data}) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const sendMessage = (
    conversation_id: number,
    message: string,
    projectId?:number | undefined
): Promise<Message> => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${BASE_URL}/conversations/send`, {conversation_id, message,projectId})
            .then(({data}) => {
                resolve(rawDataToMessageObject(data));
            })
            .catch((error) => {
                reject(error);
            });
    });
};
