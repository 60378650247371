/* eslint-disable jsx-a11y/anchor-is-valid */
import {Navigate, Route, Routes} from 'react-router-dom'
import {WelcomeMessage} from './WelcomeMessage'
import {VerifyEmail} from './VerifyEmail'
import {EmailVerified} from './EmailVerified'
import {MessagesLayout} from './MessagesLayout'

const MessagesPage = () => (
    <Routes>
        <Route element={<MessagesLayout/>}>
            <Route path='welcome' element={<WelcomeMessage/>}/>
            <Route path='verify' element={<VerifyEmail/>}/>
            <Route path='verified' element={<EmailVerified/>}/>
            <Route path='*' element={<Navigate to='/error/404'/>}/>
        </Route>
    </Routes>
)

export {MessagesPage}
