import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import ProjectsStates from "../../modules/dashboard/ProjectsStats";

const DashboardPage = () => {
  return (
    <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
      <div className="ms-auto">
        <ProjectsStates />
      </div>
    </div>
  );
};

const DashboardWrapper = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };
