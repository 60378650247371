import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { registerSecondaryUser, resetPassword } from '../core/_requests';
import { useDispatch } from "react-redux";
import { setPasswordReset } from "../../../store/AccountSlice";
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components';

type ParamsProps = {
    token: string;
    email: string;
};

const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    repeatPassword: '',
}

const PasswordSchema = Yup.object().shape({
    firtName: Yup.string()
    .required('First Name is required'),
    lastName: Yup.string()
    .required('Last Name is required'),
    password: Yup.string()
      .min(8, 'Minimum 8 characters')
      .max(50, 'Maximum 50 characters')
      .required('Password is required'),
    repeatPassword: Yup.string()
      .min(8, 'Minimum 8 characters')
      .max(50, 'Maximum 50 characters')
      .required('Password confirmation is required')
      .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
})

export function SecondaryRegistration() {
    const dispatch= useDispatch();
    const navigate = useNavigate();
    // @ts-ignore
    const { token, email, broker }: ParamsProps = useParams();
    const [loading, setLoading] = useState(false);
    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('Sorry, looks like there are some errors detected, please try again.');
    const formik = useFormik({
        initialValues,
        validationSchema: PasswordSchema,
        onSubmit: (values, {setSubmitting}) => {
            setLoading(true);
            setHasErrors(false);
            registerSecondaryUser(token, email,values.firstName,values.lastName, values.password, values.repeatPassword, broker)
                .then(() => {
                    setHasErrors(false);
                    setLoading(false);
                    setSubmitting(false);
                    dispatch(setPasswordReset(true));
                    navigate("/auth/login");
                })
                .catch((e) => {
                    setHasErrors(true);
                    setErrorMessage(e.response.data.message);
                })
              .finally(() => {
                  setLoading(false);
                  setSubmitting(false);
              })
        },
    });

    useEffect(() => {
        PasswordMeterComponent.bootstrap()
    }, [])

    return (
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
          <div className="text-center mb-10">
              <h1 className="text-dark fw-bolder mb-3">Create Username & Password</h1>
              <div className="text-gray-500 fw-semibold fs-6">
                Do you already know your password? &nbsp;
                  <Link to='/auth/login'>
                      <span className="link-primary fw-bold">Sign in</span>
                  </Link>
              </div>
          </div>

          {hasErrors && (
            <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>
                    {errorMessage}
                </div>
            </div>
          )}

          {/* begin::Form group */}
          <div className='fv-row mb-8'>
          <div className='fv-row mb-8'>
                        <label className='form-label fw-bolder text-dark fs-6'>Name</label>
                        <div className='d-flex'>
                        <div className='p-2'>
                        <input
                            // placeholder='First Name'
                            type='text'
                            autoComplete='off'
                            {...formik.getFieldProps('firstName')}
                            className={clsx(
                                'form-control bg-transparent',
                                {
                                    'is-invalid': formik.touched.firstName && formik.errors.firstName,
                                },
                                {
                                    'is-valid': formik.touched.firstName && !formik.errors.firstName,
                                }
                            )}
                        />
                        {formik.touched.firstName && formik.errors.firstName && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.firstName}</span>
                                </div>
                            </div>
                        )}
                            <span className='text-muted'>First Name</span>
                        </div>
                        <div className='p-2'>
                        <input
                            // placeholder='Last Name'
                            type='text'
                            autoComplete='off'
                            {...formik.getFieldProps('lastName')}
                            className={clsx(
                                'form-control bg-transparent',
                                {
                                    'is-invalid': formik.touched.lastName && formik.errors.lastName,
                                },
                                {
                                    'is-valid': formik.touched.lastName && !formik.errors.lastName,
                                }
                            )}
                        />
                        {formik.touched.lastName && formik.errors.lastName && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.lastName}</span>
                                </div>
                            </div>
                        )}
                        <span className='text-muted'>Last Name</span>
                        </div>
                        </div>
                       
                    </div>
                    <div className='fv-row mb-8'>
                        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
                        <input
                            placeholder='Email'
                            type='email'
                            autoComplete='off'
                            {...formik.getFieldProps('email')}
                            className={clsx(
                                'form-control bg-transparent',
                                {'is-invalid': formik.touched.email && formik.errors.email},
                                {
                                    'is-valid': formik.touched.email && !formik.errors.email,
                                }
                            )}
                        />
                        {formik.touched.email && formik.errors.email && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.email}</span>
                                </div>
                            </div>
                        )}
                    </div>
          </div>
         
          <div className='fv-row mb-8' data-kt-password-meter='true'>
              <div className='mb-1'>
                  <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                  <div className='position-relative mb-3'>
                      <input
                        type='password'
                        placeholder='Password'
                        autoComplete='off'
                        {...formik.getFieldProps('password')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                              'is-invalid': formik.touched.password && formik.errors.password,
                          },
                          {
                              'is-valid': formik.touched.password && !formik.errors.password,
                          }
                        )}
                      />
                      {formik.touched.password && formik.errors.password && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.password}</span>
                            </div>
                        </div>
                      )}
                  </div>
                  <div
                    className='d-flex align-items-center mb-3'
                    data-kt-password-meter-control='highlight'
                  >
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                  </div>
              </div>
              <div className='text-muted'>
                  Use 8 or more characters with a mix of letters, numbers & symbols.
              </div>
          </div>

          <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
              <input
                type='password'
                placeholder='Password confirmation'
                autoComplete='off'
                {...formik.getFieldProps('repeatPassword')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                      'is-invalid': formik.touched.repeatPassword && formik.errors.repeatPassword,
                  },
                  {
                      'is-valid': formik.touched.repeatPassword && !formik.errors.repeatPassword,
                  }
                )}
              />
              {formik.touched.repeatPassword && formik.errors.repeatPassword && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.repeatPassword}</span>
                    </div>
                </div>
              )}
          </div>
          {/* end::Form group */}

          <div className='d-grid mb-10'>
              <button
                type='submit'
                id='kt_password_reset_submit'
                className='btn btn-primary'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                  {!loading && <span className='indicator-label'>Submit</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
              </button>
          </div>
      </form>
    )
}
