import axios, {AxiosResponse} from 'axios';
import {
    PaymentIntent,
    WalletTransactionQueryResponse,
    Price, Profile, ProfileActivity,
    UpdateEmail,
    UpdatePassword,
    Country,
    Setting,
    PaymentMethod,
} from './_models';
import {UserModel} from "../../auth";

const API_URL = process.env.REACT_APP_API_URL;
const PRICE_URL = `${API_URL}/prices`;
const WALLET_URL = `${API_URL}/wallet`;
const PROFILE_URL = `${API_URL}/profile`;
const COUNTRIES_URL = `${API_URL}/countries`;

const getBasicPrice = (): Promise<Price> => {
    return axios.get(`${PRICE_URL}/basic`)
        .then((response: AxiosResponse<Price>) => response.data)
}

const getCustomerPrice = (): Promise<Price> => {
    return axios.get(`${PRICE_URL}/customer`)
      .then((response: AxiosResponse<Price>) => response.data)
}

const getTransactions = (page?: number): Promise<WalletTransactionQueryResponse> => {
    const url = page ? `${WALLET_URL}/transactions?page=${page}` : `${WALLET_URL}/transactions`
    return axios
        .get(url)
        .then((d: AxiosResponse<WalletTransactionQueryResponse>) => d.data)
}

const getBalance = (): Promise<number> => {
    return axios
        .get(`${WALLET_URL}/balance`)
        .then((response: AxiosResponse<number>) => response.data)
}

const getSettings = (): Promise<Setting> => {
    return axios
      .get(`${API_URL}/settings`)
      .then((response: AxiosResponse<Setting>) => response.data)
}

const payHours = (quantity: number): Promise<PaymentIntent | undefined> => {
    return axios
        .post(`${WALLET_URL}/pay`, {quantity})
        .then((response: AxiosResponse<PaymentIntent>) => response.data)
}

const confirmPayment = (payment_intent_id: string): Promise<void> => {
    return axios
        .post(`${WALLET_URL}/deposit`, {payment_intent_id})
}

const updateProfile = (project: Profile): Promise<UserModel | undefined> => {
    const formData = new FormData();

    for (let i in project) {
        if (project[i] !== null) {
            if (Array.isArray(project[i])) {
                for (let j in project[i]) {
                    formData.append(`${i}[${j}]`, project[i][j]);
                }
            } else {
                formData.append(i, project[i]);
            }
        }
    }

    return axios
        .post(`${PROFILE_URL}/update`, formData)
        .then((response) => response.data)
}

const updateEmail = (data: UpdateEmail): Promise<UpdateEmail | undefined> => {
    return axios
        .post(`${PROFILE_URL}/update_email`, data)
        .then((response: AxiosResponse<UpdateEmail>) => response.data)
}

const updatePassword = (data: UpdatePassword): Promise<UpdatePassword | undefined> => {
    return axios
        .post(`${PROFILE_URL}/update_password`, data)
        .then((response: AxiosResponse<UpdatePassword>) => response.data)
}

const disableAccount = (): Promise<PaymentIntent | undefined> => {
    return axios
        .post(`${PROFILE_URL}/disable_account`)
        .then((response: AxiosResponse<PaymentIntent>) => response.data)
}

const getProfileActivities = async (cursor?: string): Promise<ProfileActivity> => {
    const url = cursor
      ? `${PROFILE_URL}/activities?cursor=${encodeURIComponent(cursor)}`
      : `${PROFILE_URL}/activities`; 
    const response = await axios.get(url);
    return response.data; 
  };

const getCountries = (): Promise<Country[]> => {
    return axios
        .get(`${COUNTRIES_URL}`)
        .then((response: AxiosResponse<Country[]>) => response.data)
}

const createSetupIntent = (): Promise<string> => {
return axios.get<string>(`${API_URL}/setup-intent`)
    .then((response: AxiosResponse<string>) => response.data);
};

const confirmSetupIntent = (paymentMethodID: string, setupIntentID:string) => {
    return axios.post<string>(`${API_URL}/confirm-setup-intent`,{paymentMethodID,setupIntentID});
    };

const updatePaymentMethod = (paymentMethodID: string) => {
    return axios.post<string>(`${API_URL}/update-payment-method`,{paymentMethodID});
    };

/*Fetch the current payment method */
const getCurrentPaymentMethod=(): Promise<PaymentMethod> =>{
    return axios.get(`${API_URL}/current-payment-method`).then((response: AxiosResponse<PaymentMethod>) => response.data);
}

export {
    getTransactions, 
    getBalance,
    getSettings,
    payHours, 
    confirmPayment, 
    getBasicPrice,
    getCustomerPrice,
    updateProfile,
    getProfileActivities,
    updateEmail,
    updatePassword,
    disableAccount,
    getCountries,
    createSetupIntent,
    updatePaymentMethod,
    getCurrentPaymentMethod,
    confirmSetupIntent
}
