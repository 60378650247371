import React, {FC} from 'react'

const PurchaseButton: FC = () => (
  <a
    href=""
    className='engage-purchase-link btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 px-5 shadow-sm rounded-top-0 d-none' data-bs-toggle="modal" data-bs-target="#modal_add_hour"
  >
    Buy Hours
  </a>
)

export {PurchaseButton}
