import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { useLayout } from '../../../core/LayoutProvider';
import { usePageData } from '../../../core/PageData';

const DefaultTitle: FC = () => {
  const { pageDescription, pageBreadcrumbs, projectTitle } = usePageData();
  const { config } = useLayout();
  const [currentProjectTitle, setCurrentProjectTitle] = useState<string | undefined>(projectTitle);
  
  const location=useLocation();
  const path = location.pathname.substring(1).toString();
  const splittedPath = path.split('/')[0];
  const title = splittedPath .charAt(0).toUpperCase() + splittedPath.slice(1);

  useEffect(() => {
    setCurrentProjectTitle(projectTitle);
  }, [projectTitle]);

  return (
    <div
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_wrapper'}"
      className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-20 pb-2 pb-lg-0'
    >
      {/* begin::Heading */}
      {title && (
        <h1 className='text-dark fw-bolder my-1 fs-3 lh-1'>
          {title !== "Dashboard" && title || currentProjectTitle}
          {pageDescription && config.pageTitle && config.pageTitle.description && (
            <small className='text-muted fs-6 fw-normal ms-1'>{pageDescription}</small>
          )}
        </h1>
      )}
      {/* end::Heading */}

      {pageBreadcrumbs && pageBreadcrumbs.length > 0 && config.pageTitle && config.pageTitle.breadCrumbs && (
        <ul className='breadcrumb breadcrumb-dot fw-bold fs-8 my-1'>
          {Array.from(pageBreadcrumbs)
          .filter((l) => !l.isSeparator)
          .flatMap((item, index) => [
            item.title !== "Dashboard" && (
              <li key={`dashboard${index}`} className='breadcrumb-item text-muted'>
                <Link to="/dashboard">Dashboard</Link>
              </li>
            )        
          ])}

          {!currentProjectTitle && 
            <li className='breadcrumb-item text-muted'>{title}</li>
          }

          {currentProjectTitle && 
          <li className='breadcrumb-item text-muted'>{currentProjectTitle}</li>}
        </ul>
      )}
    </div>
  );
};

export { DefaultTitle };
