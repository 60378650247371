import {FC, useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from "../core/Auth";
import {getUserByToken} from "../core/_requests";

const EmailVerified: FC = () => {
    const [loading, setLoading] = useState(false);
    const {auth, setCurrentUser, currentUser} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!auth) navigate('/auth');
    }, []);

    const goToDashboard = async () => {
        if (!auth) return;

        setLoading(true);
        const {data: user} = await getUserByToken(auth.token);
        setCurrentUser(user);
        navigate('/dashboard');
    }

    return (
        <>
            <div className="mb-7">
                <Link to='/dashboard'>
                    <img alt="Logo" src={toAbsoluteUrl('/media/logos/DEFINANCE_White_Background.png')} style={{ height: '120px' }} />
                </Link>
            </div>
            <h1 className="fw-bolder text-gray-900 mb-5">Email Verified and Ready to Roll!</h1>
            <p className="fw-semibold fs-6 text-gray-500 mb-7">
            Your personalized team is assembling and ready to review your needs.
                <br />We are looking forward to working with you!
            </p>
            <div className="mb-0">
                <img  src={toAbsoluteUrl('/media/auth/ok.png')} className="mw-100 mh-300px theme-light-show" alt="" />
                <img  src={toAbsoluteUrl('/media/auth/ok-dark.png')} className="mw-100 mh-300px theme-dark-show" alt="" />
            </div>
            <div className="mb-0">
                {currentUser ? (
                    <button className='btn btn-sm btn-primary' disabled={loading} onClick={goToDashboard}>
                        {!loading && <span className='indicator-label'>Go To Dashboard</span>}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                        )}
                    </button>
                ) : (
                    <Link to='/auth'>
                        <button className="btn btn-sm btn-primary">Login</button>
                    </Link>
                )}
            </div>
        </>
    )
}

export {EmailVerified}