import { createSlice } from '@reduxjs/toolkit';

export const projectSlice= createSlice({
    name: 'project',
    initialState: {
        subTypes: [],
        autoApproveProject: false,
        budgetUsage:'',
        payAutomated:false,
        projectID:''
    },
    reducers: {
        setSubTypes: (state, action) => {
            state.subTypes = action.payload;
        },
        setAutoApproveProject: (state, action) => {
            state.autoApproveProject = action.payload;
        },
        setBudgetUsage: (state,action)=>{
            state.budgetUsage =action.payload;
        },
        setPayAutomated: (state,action)=>{
            state.payAutomated =action.payload;
        },
        setProjectID: (state,action)=>{
            state.projectID =action.payload;
        }
    }
})

export const { setSubTypes, setAutoApproveProject,setBudgetUsage,setPayAutomated,setProjectID } = projectSlice.actions;

export default projectSlice.reducer;