import {ErrorMessage, Field} from "formik";
import React, {useEffect, useState} from "react";
import {ICreateProject} from "./CreateProjectHelper";
import Dropzone from "dropzone";
import './style/dropzone.css';

type AttachementsProps = {
    values: ICreateProject;
    setFieldValue: any;
}

export default ({ values, setFieldValue }: AttachementsProps) => {
    const [iframeSrc, setIframeSrc] = useState<string>();
    const [files , setFiles] = useState<File[]>([]);
    const [fileToRemove , setFileToRemove] = useState<File>();

    useEffect(() => {
        if (values.video_url) {
            const video_url_slitted = values.video_url.split('/');
            let video_id = video_url_slitted[video_url_slitted.length - 1];
            video_id = video_id.split('?')[0];
            setIframeSrc(`https://www.loom.com/embed/${video_id}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`);
        } else {
            setIframeSrc(undefined);
        }
    }, [values.video_url]);

    useEffect(() => {
        let myDropzone = new Dropzone("#modal_create_project_files", {
            url: "https://spreadsheet-backend.definance.one/upload",
            maxFiles: 10,
            maxFilesize: 5, // MB
            addRemoveLinks: true,
            autoQueue: false,
            autoProcessQueue: false,
        });

        myDropzone.on("addedfile", file => {
            setFiles((prevState) => [...prevState, file]);
        });
        myDropzone.on("removedfile", current => {
            setFileToRemove(current);
        });
    }, []);

    useEffect(() => {
        if (!fileToRemove) return;

        const index = files.findIndex(file => file.name === fileToRemove.name);
        setFiles((prevState) => [
            ...prevState.slice(0, index),
            ...prevState.slice(index + 1)
        ]);
        setFileToRemove(undefined);
    }, [fileToRemove]);

    useEffect(() => {
        setFieldValue('files', files);
    }, [files]);

    return (
        <div className='w-100'>
            <div className='pb-10 pb-lg-12'>
                <h2 className='fw-bolder text-dark'>Attachements</h2>
            </div>

            <div className="fv-row mb-10">
                <div className="dropzone" id="modal_create_project_files">
                    <div className="dz-message needsclick">
                        <i className="ki-duotone ki-file-up fs-3hx text-primary">
                            <span className="path1"></span>
                            <span className="path2"></span>
                        </i>
                        <div className="ms-4">
                            <h3 className="dfs-3 fw-bold text-gray-900 mb-1">Drop files here or click to upload.</h3>
                            <span className="fw-semibold fs-4 text-muted">Upload up to 10 files</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='fv-row mb-10'>
                <label className='fs-6 fw-bold form-label'>Spreadsheet or Folder URL</label>

                <Field name='spreadsheet_url' className='form-control form-control-lg form-control-solid' placeholder={'https://website.com'} />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='spreadsheet_url' />
                </div>
            </div>
            <div className='fv-row mb-10'>
                <label className='fs-6 fw-bold form-label'>Loom Video Instructions URL</label>

                <Field name='video_url' className='form-control form-control-lg form-control-solid' placeholder={'https://loom.com/xxxxx'} />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='video_url' />
                </div>
            </div>
            {iframeSrc && (
                <div style={{position: 'relative', paddingBottom: '56.25%', height: 0}}>
                    <iframe
                        src={iframeSrc}
                        frameBorder="0"
                        style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} />
                </div>
            )}
        </div>
    )
}