import React, {useEffect, useRef, useState} from "react";
import {Form, Formik, FormikHelpers, useFormikContext} from "formik";
import Type from "./Type";
import Details from "./Details";
import Attachments from "./Attachments";
import Budget from "./Budget";
import Ready from "./Ready";
import {KTIcon} from "../../../../_metronic/helpers";
import {
    createProjectSchemas,
    ICreateProject,
    inits,
} from "./CreateProjectHelper";
import {StepperComponent} from "../../../../_metronic/assets/ts/components";
import {createProject} from "../core/_requests";
import {useQuery} from "react-query";
import {getBalance, getSettings} from "../../../modules/accounts/core/_requests";
import SubType from "./SubType";

const CreateProject = () => {
    const stepperRef = useRef<HTMLDivElement | null>(null);
    const stepper = useRef<StepperComponent | null>(null);
    const [isSubmitButton, setSubmitButton] = useState(false);
    const [currentSchema, setCurrentSchema] = useState(createProjectSchemas[0]);
    const [initValues] = useState<ICreateProject>(inits);
    const [isLastStep, setIsLastStep] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [currentStep,setCurrentStep]= useState(0);

    const {data: balance} = useQuery(
        '/wallet/balance',
        () => getBalance()
    );
    const {data: settings} = useQuery(
      '/settings',
      () => getSettings()
    );

    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    const prevStep = () => {
        setErrorMessage(undefined);
        if (!stepper.current) {
            return
        }

        stepper.current.goPrev()

        setCurrentSchema(createProjectSchemas[stepper.current.currentStepIndex - 1])

        setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)
    }

    const submitStep = (values: ICreateProject, {setSubmitting, resetForm}: FormikHelpers<ICreateProject>) => {
        setErrorMessage(undefined);
        if (!stepper.current) {
            setSubmitting(false);
            return;
        }

        if (isSubmitButton) {
            if (balance === undefined || !settings) return;
            if (balance < settings.min_hours) {
                setErrorMessage(`You should have at least ${settings.min_hours} hour in your balance to create a project.`);
                setSubmitting(false);
                return;
            }
            createProject(values)
                .then(() => {
                    if (!stepper.current) return;
                    setIsLastStep(true);
                    stepper.current.goNext();
                })
                .catch((error: any) => {
                    if (error.response.status === 500) setErrorMessage('An error has occurred');
                    else setErrorMessage(error.response.data.message);
                })
                .finally(() => setSubmitting(false));

            return;
        }

        if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
            stepper.current.goNext()
            setCurrentStep(stepper.current.currentStepIndex + 1)
        } else {
            stepper.current.goto(1)
            resetForm()
        }

        setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber - 1)

        setCurrentSchema(createProjectSchemas[stepper.current.currentStepIndex - 1])

        setSubmitting(false);
    };

    const handleResetState = () => {
        setSubmitButton(false)
        setCurrentSchema(createProjectSchemas[0])
        setIsLastStep(false)
        setErrorMessage(undefined)
    }

    useEffect(() => {
        if (!stepperRef.current) {
            return
        }

        loadStepper()
    }, [stepperRef])

    const handleSubmit = () => {
        const forms = document.getElementsByClassName('kt_create_account_form-' + currentStep) as HTMLCollectionOf<HTMLFormElement>;
        const form = forms[0];
        if (form) {
          submitStep(initValues, {
            setStatus: () => {},
            setErrors: () => {},
            setTouched: () => {},
            setValues: () => {},
            setSubmitting: () => {},
            resetForm: () => {},
            setFieldValue: () => {},
            setFieldError: () => {},
            setFieldTouched: () => {},
            validateForm: () => Promise.resolve({}),
            validateField: () => Promise.resolve({}),
            submitForm: () => Promise.resolve(),
            setFormikState: () => {} 
        });
        }
      };

    return (
        <div className="modal fade" id="modal_create_project" role="dialog" aria-hidden="true" data-bs-focus="false">
            <div className="modal-dialog modal-fullscreen p-9">
                <div className="modal-content modal-rounded">
                    <div className="modal-header">
                        <h2>New Project</h2>
                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" 
                            onClick={() =>{ 
                                handleResetState();
                                if (!!stepper.current) {
                                    stepper?.current.goto(1)
                                }
                            }}
                            >
                            <i className="ki-duotone ki-cross fs-1">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </i>
                        </div>
                    </div>

                    <div className="modal-body scroll-y m-5">
                        <div ref={stepperRef} className='stepper stepper-links d-flex flex-column pt-15' id='create_project_stepper'>
                            <div className='stepper-nav mb-5'>
                                <div className='stepper-item current' data-kt-stepper-element='nav'>
                                    <h3 className='stepper-title'>Project Type</h3>
                                </div>

                                <div className='stepper-item' data-kt-stepper-element='nav'>
                                    <h3 className='stepper-title'>Sub Project Type</h3>
                                </div>

                                <div className='stepper-item' data-kt-stepper-element='nav'>
                                    <h3 className='stepper-title'>Project Details</h3>
                                </div>

                                <div className='stepper-item' data-kt-stepper-element='nav'>
                                    <h3 className='stepper-title'>Attachments</h3>
                                </div>

                                <div className='stepper-item' data-kt-stepper-element='nav'>
                                    <h3 className='stepper-title'>Budget</h3>
                                </div>

                                <div className='stepper-item' data-kt-stepper-element='nav'>
                                    <h3 className='stepper-title'>Project Ready</h3>
                                </div>
                            </div>

                            <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
                                {({values, setFieldValue, setFieldError, isSubmitting, isValid, resetForm}) => (
                                    <Form className={`mx-auto mw-600px w-100 pt-15 pb-10 kt_create_account_form-${currentStep}`} id='kt_create_account_form'>
                                        <div className='current' data-kt-stepper-element='content'>
                                            <Type type_id_value={values.main_type_id} submitForm={handleSubmit}/>
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            <SubType type_id_value={values.type_id} submitForm={handleSubmit}/>
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            <Details values={values} setFieldValue={setFieldValue}
                                                     setFieldError={setFieldError}/>
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            <Attachments values={values} setFieldValue={setFieldValue}/>
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            <Budget />
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            <Ready resetFormData={() => {
                                                if (stepper.current) {
                                                    stepper.current.goto(1)
                                                    handleResetState()
                                                    resetForm()
                                                }
                                            }}
                                            />
                                        </div>

                                        {errorMessage && (
                                            <div className='alert alert-danger mt-3' role='alert'>
                                                {errorMessage}
                                            </div>
                                        )}

                                        {currentStep >= 4 &&
                                        <>
                                              {!isLastStep && (
                                            <div className='d-flex flex-stack pt-15'>
                                                <div className='mr-2'>
                                                    {/* <button
                                                        onClick={prevStep}
                                                        type='button'
                                                        className='btn btn-lg btn-light-primary me-3'
                                                        data-kt-stepper-action='previous'
                                                    >
                                                        <KTIcon iconName='arrow-left' className='fs-4 me-1'/>
                                                        Back
                                                    </button> */}
                                                </div>

                                                <div>
                                                    <button type='submit' className='btn btn-lg btn-primary me-3'
                                                            disabled={isSubmitting || !isValid} id="submit-id">
                                                        {!isSubmitting && (
                                                            <span className='indicator-label'>
                                                                {!isSubmitButton && 'Continue'}
                                                                {isSubmitButton && 'Submit'}
                                                                <KTIcon iconName='arrow-right'
                                                                        className='fs-3 ms-2 me-0'/>
                                                            </span>
                                                        )}
                                                        {(isSubmitting && isValid) && (
                                                            <span className='indicator-progress'
                                                                  style={{display: 'block'}}>
                                                                Please wait...
                                                                <span
                                                                    className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                            </span>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                        </>
                                        }
                                    </Form>
                                )}
                            </Formik>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateProject