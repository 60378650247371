import axios, { AxiosResponse } from "axios";
import {Project} from "../../../pages/project/core/_models";

const API_URL = process.env.REACT_APP_API_URL;
const PROJECTS_URL = `${API_URL}/projects`;

const getProjectDetails = (projectId: number): Promise<Project> => {
  return axios
    .get(`${PROJECTS_URL}/${projectId}/view`)
    .then((response: AxiosResponse<Project>) => response.data);
};

const authorizeAutomaticPayment =(projectId: number) =>{
  return axios .post(`${PROJECTS_URL}/${projectId}/automatic_payments`, {projectId:projectId})
}

const disableAutomaticPayment =(projectId: number) =>{
  return axios .post(`${PROJECTS_URL}/${projectId}/disable_automatic_payment`, {projectId:projectId})
}

export { getProjectDetails, authorizeAutomaticPayment, disableAutomaticPayment };
