import {FC, useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from "../core/Auth";
import {getUserByToken} from "../core/_requests";

const WelcomeMessage: FC = () => {
    const [loading, setLoading] = useState(false);
    const {auth, setCurrentUser} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!auth) navigate('/auth');
    }, []);

    const goToDashboard = async () => {
        if (!auth) return;

        setLoading(true);
        const {data: user} = await getUserByToken(auth.token);
        setCurrentUser(user);
        navigate('/dashboard');
    }

    return (
        <>
            <div className="mb-7">
                <Link to='/dashboard'>
                    <img alt="Logo" src={toAbsoluteUrl('/media/logos/DEFINANCE_White_Background.png')} style={{ height: '120px' }} />
                </Link>
            </div>
            <h1 className="fw-bolder text-gray-900 mb-5">Welcome to DeFinance!</h1>
            <div className="fw-semibold fs-6 text-gray-500 mb-7">Take your business to the next level with your personalized out of
                <br />the box finance team.</div>
            <div className="mb-0">
                <img src={toAbsoluteUrl('/media/auth/welcome.png')} className="mw-100 mh-300px theme-light-show" alt="" />
                <img src={toAbsoluteUrl('/media/auth/welcome-dark.png')} className="mw-100 mh-300px theme-dark-show" alt="" />
            </div>
            <div className="mb-0">
                <button className='btn btn-sm btn-primary' disabled={loading} onClick={goToDashboard}>
                    {!loading && <span className='indicator-label'>Go To Dashboard</span>}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
        </>
    )
}

export {WelcomeMessage}