import {FC, useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from "../core/Auth";
import {verifyEmail} from '../../../../app/modules/auth'
import React, { useState } from 'react';

const VerifyEmail: FC = () => {
    const { auth, currentUser } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [verificationMessage, setVerificationMessage] = useState('');
    const[secondVerifMsg,setSecondVerifMsg]= useState(false);

    const handleVerificationLinkClick = () => {
        if (currentUser && currentUser.id && auth) {
            setLoading(true);

            verifyEmail(auth.token)
                .then(() => {
                    setLoading(false);
                    setVerificationMessage('Verification link sent successfully!');
                    handleSecondVerification();
                })
                .catch(error => {
                    console.error('Error sending verification link:', error);
                    setLoading(false);
                    setVerificationMessage('An error occurred while sending the verification link.');
                });     
        }
        
    };
    useEffect(() => {
        if (!auth) navigate('/auth');
    }, []);

    const handleSecondVerification = () =>{
        setSecondVerifMsg(true);
    }

    return (
        <>
            <div className="mb-14">
                <Link to='/dashboard'>
                    <img alt="Logo" src={toAbsoluteUrl('/media/logos/DEFINANCE_White_Background.png')} style={{ height: '120px' }} />
                </Link>
            </div>
            {!secondVerifMsg ? 
            <>
            <h1 className="fw-bolder text-gray-900 mb-5">Verify your email using the link sent to you.</h1>
            <div className="fs-6 mb-8">
                <span className="fw-semibold text-gray-500">Didn't receive an email? Check your spam first, if it isn't there let us </span>
                <button className="link-primary fw-bold bg-transparent border-0" onClick={handleVerificationLinkClick}>SEND IT AGAIN</button>
            </div>
            </>
            :
            <h1 className="fw-bolder text-gray-900 mb-5">Verify your email using the link sent to you again.</h1>
            }
            <div className="mb-11">
                <Link to='/message/welcome'>
                    <button className="btn btn-sm btn-primary">Verify Later</button>
                </Link>
            </div>
            <div className="mb-0">
                <img  src={toAbsoluteUrl('/media/auth/please-verify-your-email.png')} className="mw-100 mh-300px theme-light-show" alt="" />
                <img  src={toAbsoluteUrl('/media/auth/please-verify-your-email-dark.png')} className="mw-100 mh-300px theme-dark-show" alt="" />
            </div>
        </>
    )
}

export {VerifyEmail}