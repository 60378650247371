import {ErrorMessage, Field} from "formik";
import React, {useEffect, useRef, useState} from "react";
import {KTIcon} from "../../../../_metronic/helpers";
import {FILE_SIZE, ICreateProject, SUPPORTED_FORMATS} from "./CreateProjectHelper";
import flatpickr from "flatpickr";

type DetailsProps = {
    values: ICreateProject;
    setFieldValue: any;
    setFieldError: any;
}

const Details = ({ values, setFieldValue, setFieldError }: DetailsProps) => {
    const logoInput = useRef();
    const [logoURL, setLogoURL] = useState<string>('media/icons/duotune/abstract/abs025.svg');
    const datePicker = flatpickr("#due_date_field", {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        altInput: true,
        altFormat: "F j, Y H:i",
        onChange: function(selectedDates, dateStr, instance) {
            setFieldValue('deadline', dateStr);
        },
    });
    
    useEffect(() => {
        if (!values.no_deadline) {
            // @ts-ignore
             //datePicker.clear();
            setFieldValue('deadline', '');
            setFieldError('deadline', '');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.no_deadline]);

    const onLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const logo = event.target.files && event.target.files[0];
        if (!logo) return;

        if (logo.size > FILE_SIZE) {
            setFieldError('logo', 'Selected file is too big.');
            return;
        }

        if (!SUPPORTED_FORMATS.includes(logo.type)) {
            setFieldError('logo', 'Selected file has unsupported format.');
            return;
        }

        setFieldValue("logo", logo);
        setFieldError('logo', '');
        setLogoURL(URL.createObjectURL(logo));
    };

    const onLogoCancel = () => {
        setFieldValue("logo", undefined);
        setFieldError('logo', '');
        setLogoURL('media/icons/duotune/abstract/abs025.svg');
        if (logoInput.current) {
            // @ts-ignore
            logoInput.current.value = "";
        }
    }

    return (
        <div className='w-100'>
            <div className='pb-10 pb-lg-12'>
                <h2 className='fw-bolder text-dark'>Project Details</h2>
            </div>

            <div className="fv-row mb-10">
                <label className="form-label">Logo <span className="fs-8 text-muted">(Optional)</span></label>
                <div className="col-lg-8">
                    <div className="image-input image-input-outline image-input-empty"
                         style={{backgroundImage: "url(media/icons/duotune/abstract/abs025.svg)"}}>
                        <div className="image-input-wrapper w-125px h-125px" style={{backgroundImage: `url(${logoURL})`, backgroundPosition: 'center'}}></div>
                        <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Edit">
                            <KTIcon iconName='pencil' className='fs-4 pe-0' />
                            {/*@ts-ignore*/}
                            <input name="logo" type="file" accept=".png, .jpg, .jpeg" onChange={onLogoChange} ref={logoInput} />
                        </label>
                        {values.logo && (
                            <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow d-inline" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="Remove" onClick={onLogoCancel}>
                                <KTIcon iconName='cross' className='fs-2 pe-0 pt-1' />
                            </span>
                        )}
                    </div>
                    <div className="form-text">Allowed file types: png, jpg, jpeg. Max size: 2Mb</div>
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='logo' />
                    </div>
                </div>
            </div>

            <div className='fv-row mb-10'>
                <label className='form-label required'>Project Name</label>

                <Field name='title' className='form-control form-control-lg form-control-solid' />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='title' />
                </div>
            </div>

            <div className='fv-row mb-10'>
                <label className='form-label'>Project Description <span className="fs-8 text-muted">(Optional)</span></label>

                <Field
                    as='textarea'
                    name='description'
                    className='form-control form-control-lg form-control-solid'
                    rows={3}
                ></Field>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='description' />
                </div>
            </div>

            <div className="fv-row mb-10">
                <label className={`${values.no_deadline && 'required'} fs-6 fw-semibold mb-2`}>Project Deadline</label>
                {values.no_deadline && (
                    <div className="position-relative d-flex align-items-center">
                        <KTIcon iconName='calendar-8' className='fs-2 position-absolute mx-4' />
                        <Field id={'due_date_field'} className="form-control form-control-solid ps-12" placeholder="Pick due date" name="deadline" />
                    </div>
                )}
                <div className='text-danger mt-2'>
                    <ErrorMessage name='deadline' />
                </div>
                <label className='form-check form-switch form-check-custom form-check-solid mt-3'>
                    <Field name={'no_deadline'} className='form-check-input h-20px w-35px' type='checkbox' />
                    <span className='form-check-label fw-bold text-gray-500'>This project has a specific due date.</span>
                </label>

            </div>
        </div>
    )
}

export default Details;