/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {replace, useFormik} from 'formik'
import {getUserByToken, googleAuth, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {useGoogleLogin} from "@react-oauth/google";
import {useDispatch, useSelector} from "react-redux";
import { setPasswordReset } from "../../../store/AccountSlice";
import { locationState } from '../core/_models'

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .min(3, 'Minimum 3 characters')
        .max(50, 'Maximum 50 characters')
        .email('Wrong email format')
        .required('Email is required'),
    password: Yup.string()
        .min(3, 'Minimum 3 characters')
        .max(50, 'Maximum 50 characters')
        .required('Password is required'),
})

const initialValues = {
    email: '',
    password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
    const dispatch= useDispatch();
    const  location = useLocation();
    const navigate = useNavigate();
    const previousLocation: locationState  = location.state as locationState;
    const passwordReset = useSelector((state: any) => state.account.passwordReset);
    const [loading, setLoading] = useState(false)
    const [googleLoading, setGoogleLoading] = useState(false);
    const [googleAuthError, setGoogleAuthError] = useState<string>();
    const {saveAuth, setCurrentUser} = useAuth();
    const [message, setMessage] = useState<undefined|string>(undefined);

    const googleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            setGoogleLoading(true);
            const auth = await googleAuth(tokenResponse.access_token)
                .then(response => response.data)
                .catch((error) => {
                    if (error.response.status === 422) {
                        setGoogleAuthError(error.response.data.message);
                    } else {
                        setGoogleAuthError('Fail to login with Google');
                    }
                    setGoogleLoading(false);
                    return Promise.reject();
                });
            saveAuth(auth)
            const {data: user} = await getUserByToken(auth.token)
            setCurrentUser(user)
            if(previousLocation){
                navigate(previousLocation.originalUrl);
            }
        },
        onError: errorResponse => {
            saveAuth(undefined)
            setGoogleAuthError('Fail to login with Google')
            setGoogleLoading(false)
        },
    });

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            try {
                const {data: auth} = await login(values.email, values.password)
                saveAuth(auth)
                const {data: user} = await getUserByToken(auth.token)
                setCurrentUser(user)
                if(previousLocation){
                    navigate(previousLocation.originalUrl);
                }
            } catch (error) {
                console.error(error)
                saveAuth(undefined)
                setStatus('The login details are incorrect')
                setSubmitting(false)
                setLoading(false)
            }
        },
    });

    useEffect(() => {
        if (passwordReset) {
            setMessage('Your password has been updated. Please login using your new password.');
            dispatch(setPasswordReset(false));
        }
    }, [passwordReset]);

    return (
        <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
            <div className='text-center mb-11'>
                <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
            </div>

            {message && (
                <div className='mb-10 bg-light-primary p-8 rounded'>
                    <div className='text-primary'>{message}</div>
                </div>
            )}

            {googleLoading ? (
              <div className="text-center">
                  <span className="spinner-border align-middle"></span>
              </div>
            ) : (
                <>
                    <div className='row g-3 mb-9'>
                        <div className='offset-md-3 col-md-6'>
                            <button
                                type={'button'}
                                className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
                                onClick={() => googleLogin()}
                            >
                                <img
                                    alt='Logo'
                                    src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                                    className='h-15px me-3'
                                />
                                Sign in with Google
                            </button>
                        </div>
                    </div>

                    <div className='separator separator-content my-14'>
                        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
                    </div>

                    {formik.status && (
                        <div className='mb-lg-15 alert alert-danger'>
                            <div className='alert-text font-weight-bold'>{formik.status}</div>
                        </div>
                    )}

                    {googleAuthError && (
                        <div className='mb-lg-15 alert alert-danger'>
                            <div className='alert-text font-weight-bold'>{googleAuthError}</div>
                        </div>
                    )}

                    <div className='fv-row mb-8'>
                        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                        <input
                            placeholder='Email'
                            {...formik.getFieldProps('email')}
                            className={clsx(
                                'form-control bg-transparent',
                                {'is-invalid': formik.touched.email && formik.errors.email},
                                {
                                    'is-valid': formik.touched.email && !formik.errors.email,
                                }
                            )}
                            type='email'
                            name='email'
                            autoComplete='off'
                        />
                        {formik.touched.email && formik.errors.email && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.email}</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className='fv-row mb-3'>
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                        <input
                            type='password'
                            autoComplete='off'
                            placeholder='Password'
                            {...formik.getFieldProps('password')}
                            className={clsx(
                                'form-control bg-transparent',
                                {
                                    'is-invalid': formik.touched.password && formik.errors.password,
                                },
                                {
                                    'is-valid': formik.touched.password && !formik.errors.password,
                                }
                            )}
                        />
                        {formik.touched.password && formik.errors.password && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.password}</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>

                        <Link to='/auth/forgot-password' className='link-primary'>
                            Forgot Password ?
                        </Link>
                    </div>

                    <div className='d-grid mb-10'>
                        <button
                            type='submit'
                            id='kt_sign_in_submit'
                            className='btn btn-primary'
                            disabled={formik.isSubmitting || !formik.isValid}
                        >
                            {!loading && <span className='indicator-label'>Continue</span>}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                  Please wait...
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>

                    <div className='text-gray-500 text-center fw-semibold fs-6'>
                        Not a Member yet?{' '}
                        <Link to='/auth/registration' className='link-primary'>
                            Sign up
                        </Link>
                    </div>
                </>
            )}
        </form>
    )
}
