import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL;
const API_URL_V1= process.env.REACT_APP_API_URL2_V1;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`;
export const REGISTER_URL = `${API_URL}/register`;

export const GOOGLE_AUTH_URL = `${API_URL}/auth/google`;

export const REQUEST_PASSWORD_URL = `${API_URL}/forgot-password`;
export const RESET_PASSWORD_URL = `${API_URL}/reset-password`;

export const VERIFY_EMAIL_URL = `${API_URL}/email/verification-notification`;

export const CHECK_EMAIL_URL = `${API_URL}/check_email`;

export const GET_PROJECTS = `${API_URL}/projects`;

export const CONTACT_URL = `${API_URL_V1}/contact`;

export const REGISTER_SECONDARY_USER_URL = `${API_URL}/secondary-registration`;


// Server should return AuthModel
export function login(email: string, password: string) {
    return axios.post<AuthModel>(LOGIN_URL, {
        email,
        password,
    })
}

// Server should return AuthModel
export function register(
    firstname: string,
    lastname: string,
    email: string,
    password: string,
    password_confirmation: string
) {
    return axios.post<AuthModel>(REGISTER_URL, {
        email,
        firstname,
        lastname,
        password,
        password_confirmation,
    })
}

export function googleAuth(code: string) {
    return axios.post<AuthModel>(GOOGLE_AUTH_URL, {
        token: code,
    })
}

export function requestPassword(email: string) {
    return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
        email,
    })
}

export function resetPassword(token: string, email: string, password: string, password_confirmation: string, broker?: string) {
    return axios.post<{ result: boolean }>(RESET_PASSWORD_URL, {
        token,
        email,
        password,
        password_confirmation,
        broker,
    })
}

export function verifyEmail(token: string): Promise<boolean> {
    const url = `${VERIFY_EMAIL_URL}`;

    const headers = {
        Authorization: `Bearer ${token}`,
    };

    return axios.post<{ result: boolean }>(url, {}, { headers })
        .then(response => response.data.result);
}

export function getUserByToken(token: string) {
    return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
        api_token: token,
    })
}

export function checkEmail(email: string) {
    return new Promise<boolean>((resolve, reject) => {
        axios.post<{ result: boolean }>(CHECK_EMAIL_URL, {
            email,
        }).then((response) => {
            resolve(true)
        }).catch((error) => {
            if (error.response.data.message === "Email is already in use.") {
                resolve(false);
            }
        })
    })
}

export function sendContactMessage(data:any): Promise<void> {
    return axios.post(`${CONTACT_URL}`,data);
}

export function registerSecondaryUser(token: string, email: string,firstname: string,lastname: string, password: string, password_confirmation: string, broker?: string) {
    return axios.post<{ result: boolean }>(REGISTER_SECONDARY_USER_URL, {
        token,
        email,
        firstname,
        lastname,
        password,
        password_confirmation,
        broker,
    })
}


 