import { createSlice } from '@reduxjs/toolkit';

export const projectSlice= createSlice({
    name: 'files',
    initialState: {
        currentFile: null,
        currentRemoteFile: null,
    },
    reducers: {
        setCurrentFile: (state, action) => {
            state.currentFile = action.payload;
        },
        setCurrentRemoteFile: (state, action) => {
            state.currentRemoteFile = action.payload;
        },
    }
})

export const { setCurrentFile, setCurrentRemoteFile } = projectSlice.actions;

export default projectSlice.reducer;