/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'
import {Link} from 'react-router-dom'
import {useAuth} from "../../../../app/modules/auth";
import {verifyEmail} from '../../../../app/modules/auth'
import React, {useState} from 'react';


export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout()
  const {header, pageTitle} = config
  const {currentUser, auth} = useAuth();
  const {email_verified_at, lastname, firstname} = currentUser || {};
  const [loading, setLoading] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');

  const handleVerificationLinkClick = () => {
    if (currentUser && currentUser.id && auth) {
      setLoading(true);

      verifyEmail(auth.token)
        .then(() => {
          setLoading(false);
          setVerificationMessage('Verification link sent successfully!');
        })
        .catch(error => {
          console.error('Error sending verification link:', error);
          setLoading(false);
          setVerificationMessage('An error occurred while sending the verification link.');
        });
    }
  };

  const isEmpty = (str) => (!str?.length);

  return (
    <>
      <div>
        {currentUser && email_verified_at === null && (
          <div className="d-flex justify-content-center align-content-center w-100">
            <p className="text-center w-100 text-white mb-0"
               style={{background: verificationMessage ? '#b2de8c' : '#00c7fc'}}>
              {verificationMessage ? verificationMessage :
                <>
                  Please verify your email address to be able to submit a new project or resend the{' '}
                  <a href="#" className="cursor-pointer text-decoration-underline text-white"
                     onClick={handleVerificationLinkClick}>
                    verification link
                  </a>
                </>
              }
              {loading && <span className="spinner-border spinner-border-sm ms-2"></span>}
            </p>
          </div>
        )}
        {currentUser && (isEmpty(firstname) || isEmpty(lastname)) && (
          <div className="d-flex justify-content-center align-content-center w-100">
            <p className="text-center w-100 text-white bg-warning">
              You need to enter your firstname and your lastname to be able to submit a new project. Go to {' '}
              <Link to={"/profile"} className="cursor-pointer text-decoration-underline text-white">
                Profile page
              </Link>
            </p>
          </div>
        )}
        <div
          id="kt_header"
          className={clsx(
            "header",
            classes.header.join(" "),
            " align-items-stretch"
          )}
          {...attributes.headerMenu}
        >
          {/* begin::Container */}
          <div
            className={clsx(
              classes.headerContainer.join(' '),
              'd-flex align-items-stretch justify-content-between container'
            )}
          >
            {/*begin::Aside mobile toggle */}

            {/* <div
                className="d-flex align-items-center d-lg-none ms-n3 me-1"
                title="Show aside menu"
            >
                <div
                className="btn btn-icon btn-active-color-primary w-40px h-40px"
                id="kt_aside_toggle"
                >
                <KTIcon iconName="abstract-14" className="fs-1" />
                </div>
            </div> */}
            {/*end::Aside mobile toggle */}

            {/* begin::Mobile logo */}
            {/* <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                <Link to="/dashboard" className="d-lg-none">
                <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/DefinanceSS_black.png")}
                    className="h-30px theme-light-show"
                />
                <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/DefinanceSS_logo.png")}
                    className="h-30px theme-dark-show"
                />
                </Link>
            </div> */}
            {/* end::Mobile logo */}

            {/* logo */}
            <div className="d-flex align-items-center" id="kt_header_wrapper">
              <Link to={'/dashboard'}>
                <img alt="Logo" src={toAbsoluteUrl("/media/logos/DEFINANCE_White_Background.png")}
                     className="h-100px theme-light-show"/>
                <img alt="Logo" src={toAbsoluteUrl("/media/logos/DeFinance-light-logo.png")}
                     className="h-70px theme-dark-show"/>
              </Link>
            </div>

            {/* begin::Wrapper */}
            <div
              className={clsx(
                "d-flex align-items-stretch",
                `justify-content-${header.menu ? "between" : "end"}`,
                "flex-lg-grow-1"
              )}
            >
              {header.menu && (
                <div className="d-flex align-items-stretch" id="kt_header_nav">
                  <Header/>
                </div>
              )}

              <Topbar/>
            </div>
            {/* end::Wrapper */}
          </div>

          {/* end::Container */}
        </div>
        <div
          className={clsx(classes.header.join(" "), " align-items-stretch title")}
        >
          <div
            className={clsx(
              "d-flex align-items-stretch justify-content-between container py-3 py-lg-5"
            )}
          >
            {pageTitle?.display && (
              <div className="d-flex align-items-center" id="kt_header_wrapper">
                <DefaultTitle/>
              </div>
            )}
          </div>
        </div>


      </div>
    </>
  );
}
