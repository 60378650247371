import * as Yup from "yup";

export const FILE_SIZE: number = 2000000;
export const SUPPORTED_FORMATS = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp', 'image/jpg', 'image/svg'];

export interface ICreateProject {
    title: string;
    description: string;
    main_type_id: number | undefined;
    type_id: number | undefined;
    deadline: string;
    no_deadline: boolean;
    budget_usage: 'quote' | 'free';
    budget_amount: number | undefined;
    spreadsheet_url: string;
    video_url: string;
    logo: File | undefined;
    files: File[] | undefined;
}

export const createProjectSchemas = [
    Yup.object({
        main_type_id: Yup.number().required('Project type required').label('Type required'),
    }),
    Yup.object({
        type_id: Yup.number().required('Project sub type required').label('Sub Type required'),
    }),
    Yup.object({
        title: Yup.string().required('Project Name Required').label('Project Title'),
        no_deadline: Yup.boolean(),
        deadline: Yup.string().label('Project Deadline').when('no_deadline', (no_deadline, schema) => {
            if (no_deadline[0]) {
                return Yup.string().required('Project Deadline Required');
                
            } else {
                return Yup.string().notRequired();
            }
        }),
        logo: Yup.mixed().nullable().notRequired(),
    }),
    Yup.object({
        spreadsheet_url: Yup.string().url().label('Spreadsheet URL'),
        video_url: Yup.string().url().label('Loom Video URL'),
        files: Yup.mixed().nullable().notRequired(),
    }),
    Yup.object({
        budget_usage: Yup.string().required().label('Budget Usage'),
    }),
];

export const inits: ICreateProject = {
    title: '',
    description: '',
    main_type_id: undefined,
    type_id: undefined,
    deadline: '',
    no_deadline: false,
    budget_usage: 'quote',
    budget_amount: 0,
    spreadsheet_url: '',
    video_url: '',
    logo: undefined,
    files: undefined,
}