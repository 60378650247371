import { ErrorMessage } from "formik";
import React from "react";
import {KTIcon} from "../../../../_metronic/helpers";
import {getBalance, getSettings} from "../../../modules/accounts/core/_requests";
import {useQuery} from "react-query";
import {useDispatch} from "react-redux";
import {setAddingHourFrom, setHourToAdd} from "../../../store/AccountSlice";

export default () => {
    const dispatch= useDispatch();
    const {data: balance} = useQuery(
        '/wallet/balance',
        () => getBalance()
    );
    const {data: settings} = useQuery(
      '/settings',
      () => getSettings()
    );

    const handleAddHours = () => {
        if (!settings) return;
        dispatch(setAddingHourFrom('project_form'));
        dispatch(setHourToAdd(Math.ceil(settings.min_hours)));
    }

    const triggerSubmit = () =>{
        const submitButton= document.getElementById("submit-id");
        submitButton?.click();
    }

    return (
        <div className='w-100'>
            <div className='pb-5 pb-lg-12' >
                <h2 className='fw-bolder text-dark'>Budget</h2>
                <div className="text-muted fw-semibold fs-4">
                    Once you submit your project, we will work with you to determine what the pricing should look like. You will not be billed anything until you approve the final budget.
                </div>
            </div>

            {(balance !== undefined && settings) && (
                balance < settings.min_hours ? (
                    <div className="mb-5">
                        <div className="alert alert-warning d-flex align-items-center p-5">
                            <i className="ki-duotone ki-shield-tick fs-2hx text-warning me-4"><span
                                className="path1"></span><span className="path2"></span></i>
                            <div className="d-flex flex-column">
                                <h4 className="mb-1 text-dark">Hour Needed for Submission</h4>
                                <p>
                                    You must have at least a {settings.min_hours} hour balance in order to create a project.
                                    <br/>
                                    This hour will be applied to a future approved project and is <strong>NOT</strong> used at this time when submitting for an estimate.
                                    <br/>
                                    <span className="text-black">Current Account Balance: {balance} hours</span>
                                </p>
                            </div>
                        </div>
                        <div className='text-center'>
                            <button type='button' className='btn btn-light-primary'
                                    onClick={handleAddHours} data-bs-toggle="modal"
                                    data-bs-target="#modal_add_hour">
                                <KTIcon iconName='credit-cart' className='fs-4 me-1'/>
                                Buy hours
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="fv-row mb-8">
                        <div className="row g-9" data-kt-buttons="true" onClick={ triggerSubmit }>
                            <div className="col-12">
                                <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6 active`} data-kt-button="true">
                                    <span className="ms-5">
                                        <span className="fs-4 fw-bold text-gray-800 mb-2 d-block">Get a Quote</span>
                                        <span className="fw-semibold fs-7 text-gray-600">We will look over your project and send a quote for how long we think it will take to complete. Although we do our best, this does not guarantee completion in the quoted timeline.</span>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className='text-danger mt-2'>
                            <ErrorMessage name='budget_usage'/>
                        </div>
                    </div>
                )
            )}
        </div>
    );
}