import {ErrorMessage, Field} from "formik";
import {ProjectType} from "../core/_models";
import React, {useEffect} from "react";
import {getProjectTypes} from "../core/_requests";
import {useQuery} from "react-query";
import {KTIcon} from "../../../../_metronic/helpers";
import {useDispatch} from "react-redux";
import {setSubTypes} from "../../../store/ProjectSlice";

type TypeItemProps = {
    type_id_value: number | undefined;
    type: ProjectType;
    submitForm: () => void;
};

type TypeProps = {
    type_id_value: number | undefined;
    submitForm: () => void;
}

const TypeItem = ({ type_id_value, type, submitForm }: TypeItemProps) => {
    return (
        <>
            <Field
                type='radio'
                className='btn-check'
                name='main_type_id'
                value={type.id}
                onClick={submitForm}
                id={`create_project_form_type_${type.id}`}
            />
            <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6 mb-6 ${type_id_value == type.id && 'active'}`} htmlFor={`create_project_form_type_${type.id}`}>
                <span className="d-flex">
                    <i className={`${type.icon} fs-3x me-5`}></i>
                    <span className="ms-4">
                        <span className="fs-3 fw-bold text-gray-900 mb-2 d-block">{type.name}</span>
                        <span className="fw-semibold fs-4 text-muted">{type.description}</span>
                    </span>
                </span>
            </label>
        </>
    );
}

export default ({ type_id_value,submitForm }: TypeProps) => {
    const dispatch= useDispatch();
    const {isLoading, data: projectTypes, error: typeError} = useQuery(
        '/project/types',
        () => getProjectTypes()
    );

    useEffect(() => {
        if (!projectTypes) return;
        let selectedType = projectTypes.find((type) => type.id == type_id_value);

        if (!selectedType) return;
        dispatch(setSubTypes(selectedType.subTypes));
    }, [type_id_value]);

    return (
        // @ts-ignore
        <div className='w-100'>
            <div className="pb-7 pb-lg-12">
                <h1 className="fw-bold text-dark">Choose Project Type</h1>
            </div>
            {isLoading && (
                <div className="text-center">
                    <span className="spinner-border align-middle"></span>
                </div>
            )}
            {typeError && (
                <div className="alert alert-danger d-flex align-items-center p-5 mb-10">
                    <KTIcon iconName='cross' className='fs-2x me-3 text-danger' />

                    <div className="d-flex flex-column">
                        <h5 className="mb-1">Error</h5>
                        <span>An error occurred while fetching the types. Please try again later.</span>
                    </div>
                </div>
            )}
            {projectTypes && (
                <div className="fv-row mb-15" data-kt-buttons="true">
                    {projectTypes.map((type, index) => <TypeItem type_id_value={type_id_value} type={type} key={index} submitForm={submitForm} />)}
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='type_id'/>
                    </div>
                </div>
            )}
        </div>
    )
}