import React, { useEffect, useState } from 'react';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {Link, Outlet, useNavigate} from "react-router-dom";
import {KTIcon, toAbsoluteUrl} from "../../../_metronic/helpers";
import {confirmPayment} from "../../modules/accounts/core/_requests";

const STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
if (!STRIPE_KEY) {
    throw new Error('Stripe public key is not defined');
  }
const stripePromise = loadStripe(STRIPE_KEY);

type PaymentStatusProps = {
    clientSecret: string | undefined;
};

const PaymentStatus = ({ clientSecret }: PaymentStatusProps) => {
    const stripe = useStripe();
    const [message, setMessage] = useState<string>();
    const [status, setStatus] = useState<string>();

    useEffect(() => {
        if (!stripe || !clientSecret) return;

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            setStatus(paymentIntent?.status);
            switch (paymentIntent?.status) {
                case 'succeeded':
                    setMessage('Success! Thank you for your payment.');
                    break;

                case 'processing':
                    setMessage("Payment processing. We'll update you when payment is received.");
                    break;

                case 'requires_payment_method':
                    // Redirect your user back to your payment page to attempt collecting
                    // payment again
                    setMessage('Payment failed. Please try another payment method.');
                    break;

                default:
                    setMessage('Something went wrong.');
                    break;
            }
        });
    }, [stripe]);

    return (
        <div className='d-flex flex-column flex-root'>
            <div className='d-flex flex-column flex-center flex-column-fluid'>
                <div className='d-flex flex-column flex-center text-center p-10'>
                    <div className='card card-flush  w-lg-650px py-5'>
                        <div className='card-body py-15 py-lg-20'>
                            <div className="fw-semibold fs-3 mb-7">
                                {message}
                            </div>
                            <div className="mb-10">
                                {status ? (
                                    <div className={'flex justify-center'}>
                                        {status === 'succeeded' ? (
                                            <KTIcon iconName='check-circle' className='fs-3x me-5 text-primary' />
                                        ) : status === 'processing' ? (
                                            <KTIcon iconName='information-5' className='fs-3x me-5 text-info' />
                                        ) : (
                                            <KTIcon iconName='cross-circle' className='fs-3x me-5 text-danger' />
                                        )}
                                    </div>
                                ) : (
                                    <div className="text-center">
                                        <span className="spinner-border align-middle text-primary"></span>
                                    </div>
                                )}
                            </div>
                            <div className="mb-10">
                                <Link to={'/dashboard'}>
                                    <button className='btn btn-sm btn-primary'>
                                        <span className='indicator-label'>Go To Dashboard</span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default () => {
    const clientSecretParam = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
    const paymentIntentParam = new URLSearchParams(window.location.search).get('payment_intent');
    const navigate = useNavigate();

    const [clientSecret, setClientSecret] = useState<string>();

    useEffect(() => {
        if (clientSecretParam !== null) {
            setClientSecret(clientSecretParam);
        } else {
            navigate('/dashboard');
        }
    }, [clientSecretParam]);

    useEffect(() => {
        if (paymentIntentParam !== null) {
            confirmPayment(paymentIntentParam).then(() => console.log('Payment confirmed'));
        }
    }, [paymentIntentParam]);

    return (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
            <PaymentStatus clientSecret={clientSecret} />
        </Elements>
    );
};
