import { createSlice } from '@reduxjs/toolkit';

export const projectSlice= createSlice({
    name: 'assignments',
    initialState: {
        assignmentId: null,
    },
    reducers: {
        setAssignmentId: (state, action) => {
            state.assignmentId = action.payload;
        },
    }
})

export const { setAssignmentId } = projectSlice.actions;

export default projectSlice.reducer;