import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {ErrorMessage, Field, Form, Formik, FormikHelpers} from "formik";
import {number, object} from "yup";
import {getBalance, getCurrentPaymentMethod, getCustomerPrice, payHours} from "../../core/_requests";
import PaymentForm from "./PaymentForm";
import {useQuery} from "react-query";
import {KTIcon} from "../../../../../_metronic/helpers";
import {Appearance, loadStripe} from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';
import {useDispatch, useSelector} from "react-redux";
import {
    setAddingHourFrom,
    setPaymentIsSuccessful,
    setHourToAdd,
    setInsufficientHour,
    setPayableQuantity,
    setCurrentPaymentMethod
} from "../../../../store/AccountSlice";
import {setAutoApproveProject} from "../../../../store/ProjectSlice";
import Swal from "sweetalert2";
import { Modal } from "bootstrap";
import PaymentFormAppearance from "./PaymentFormAppearance";

const STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
if (!STRIPE_KEY) {
    throw new Error('Stripe public key is not defined');
  }
const stripePromise = loadStripe(STRIPE_KEY);

const appearance: Appearance={
    theme:'stripe',
    variables:PaymentFormAppearance.variables,
    rules: {
        '.Input, .Block': {
            backgroundColor: 'transparent',
            border: '1.5px solid var(--colorPrimary)',
        },
    },
}

type FormValues = {
    preset_quantity: string,
    custom_quantity: string,
}

type QuantitySelectionProps = {
    values: FormValues,
    setFieldValue: any,
    setQuantityToPay: Dispatch<SetStateAction<number | undefined>>,
}

const QuantitySelection = ({ values, setFieldValue, setQuantityToPay }: QuantitySelectionProps) => {
    const hourToAdd = useSelector((state: any) => state.account.hourToAdd);

    useEffect(() => {
        if (hourToAdd > 0) {
            setFieldValue('custom_quantity', hourToAdd)
        }
    }, [hourToAdd]);

    useEffect(() => {
        if (values.custom_quantity !== '') {
            setQuantityToPay(parseFloat(values.custom_quantity));
            setFieldValue('preset_quantity', '');
        }
    }, [values.custom_quantity]);

    useEffect(() => {
        if (values.preset_quantity !== '') {
            setQuantityToPay(parseInt(values.preset_quantity));
            setFieldValue('custom_quantity', '');
        }
    }, [values.preset_quantity]);


    return (
        <>
            {/* <div className='fv-row mb-10'>
                <label className='form-label required'>How many hours would you like to purchase ?</label>
                <div className='row mb-2' data-kt-buttons='true'>
                    <div className='col'>
                        <Field
                            type='radio'
                            className='btn-check'
                            name='preset_quantity'
                            value='10'
                            id='add_hour_select_1'
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='add_hour_select_1'
                        >
                            <span className='fw-bolder fs-3'>10</span>
                        </label>
                    </div>

                    <div className='col'>
                        <Field
                            type='radio'
                            className='btn-check'
                            name='preset_quantity'
                            value='20'
                            id='add_hour_select_2'
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='add_hour_select_2'
                        >
                            <span className='fw-bolder fs-3'>20</span>
                        </label>
                    </div>

                    <div className='col'>
                        <Field
                            type='radio'
                            className='btn-check'
                            name='preset_quantity'
                            value='50'
                            id='add_hour_select_3'
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='add_hour_select_3'
                        >
                            <span className='fw-bolder fs-3'>50</span>
                        </label>
                    </div>

                    <div className='col'>
                        <Field
                            type='radio'
                            className='btn-check'
                            name='preset_quantity'
                            value='100'
                            id='add_hour_select_4'
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='add_hour_select_4'
                        >
                            <span className='fw-bolder fs-3'>100</span>
                        </label>
                    </div>
                </div>
            </div> */}
            <div className='fv-row mb-10 mt-5'>
                <label className='form-label'>Needed Hours:</label>
                <Field type={'number'} min={1} name='custom_quantity' placeholder={'Enter custom quantity'} className='form-control form-control-lg form-control-solid' />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='custom_quantity' />
                </div>
            </div>
        </>
    );
};

export default () => {
    const dispatch= useDispatch();
    const addingHourFrom = useSelector((state: any) => state.account.addingHourFrom);
    const insufficientHour = useSelector((state: any) => state.account.insufficientHour);
    const paymentIsSuccessful = useSelector((state: any) => state.account.paymentIsSuccessful);
    const [quantityToPay, setQuantityToPay] = useState<number>();
    const {isLoading: priceIsLoading, data: price, error: priceError} = useQuery(
        '/price/basic',
        () => getCustomerPrice()
    )
    const [clientSecret, setClientSecret] = useState<string>();
    const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false);
    const [paymentError, setPaymentError] = useState<boolean>(false);
    const {data: balance} = useQuery(
        '/wallet/balance',
        () => getBalance()
    );
    const isNewPaymentMethod= useSelector((state: any) => state.account.isNewPaymentMethod);
    const paymentMethod = useSelector((state: any) => state.account.currentPaymentMethod);
   // const [paymentMethod,setPaymentMethod]=useState<PaymentMethod | null>(null);
    
    useEffect(()=>{
        getCurrentPaymentMethod().then((response)=>{
            if(response){
                //setPaymentMethod(response);
                dispatch(setCurrentPaymentMethod(response));
                        }
        })
    },[]);

    useEffect(()=>{
        getCurrentPaymentMethod().then((response)=>{
            if(response){
                dispatch(setCurrentPaymentMethod(response));
            }
        })
    },[isNewPaymentMethod]);
    

    const onSubmit = ({ preset_quantity, custom_quantity }: FormValues, { setSubmitting, setFieldError, resetForm }: FormikHelpers<FormValues>) => {
        setPaymentError(false);
        if (preset_quantity === '' && custom_quantity === '') {
            setFieldError('custom_quantity', 'Please select or enter a quantity');
            setSubmitting(false);

            return;
        }

        if (!quantityToPay || quantityToPay < 1) {
            setFieldError('custom_quantity', 'Please enter a quantity equal or greater than 1');
            setSubmitting(false);
            return;
        }
      
        if(paymentMethod!=null){
            const modal=document.getElementById('payment_methods');
            const approve_dismisalBtn=document.getElementById('approveHoursCloseModalID');
            const hours_dismisalBtn=document.getElementById('projectHoursCloseModalID');
            const default_dismisalBtn=document.getElementById('defaultHoursCloseModalID');
            if(modal){
                const paymentModal = new Modal(modal);
                dispatch(setPayableQuantity(quantityToPay));
                setSubmitting(false); 
                approve_dismisalBtn?.click();
                hours_dismisalBtn?.click();
                default_dismisalBtn?.click();
                paymentModal.toggle();
            }
        }
        else{
            payHours(quantityToPay)
            .then((payment) => {
                if (!payment) return;
                dispatch(setInsufficientHour(false));
                if (payment.status === 'succeeded') {
                    handleCloseModal();
                    const modal=document.getElementById('approveCloseModalID'); 
                     modal?.click();  
                    Swal.fire({
                        text: "Success! Thank you for your payment.",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "OK",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        }
                    }).then((result)=>{
                        if(result.isConfirmed){
                            dispatch(setAutoApproveProject(true));                     
                        }
                    })
                    resetForm();
                } else {
                    setClientSecret(payment.client_secret);
                    setShowPaymentForm(true);
                }
            })
            .catch(() => {setPaymentError(true);})
            .finally(() => setSubmitting(false));
        }
    }

    const handleCloseModal = () => {
        dispatch(setAddingHourFrom('default'));
        dispatch(setInsufficientHour(false));
        dispatch(setHourToAdd(0));
        setShowPaymentForm(false);
        setClientSecret(undefined);
    }

    const handleCloseSuccess = () => {
        if (addingHourFrom == 'approve_project') {
            dispatch(setAutoApproveProject(true));
        }
        handleCloseModal();
        dispatch(setPaymentIsSuccessful(false));
    }

    return (
        <div className="modal fade" id="modal_add_hour" role="dialog" aria-hidden="true" data-bs-backdrop='static'>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content modal-rounded">
                    <div className="modal-header">
                        <h2>Add Hours</h2>
                        {addingHourFrom == 'project_form' ? (
                            <div className="btn btn-sm btn-icon btn-active-color-primary" id="projectHoursCloseModalID" onClick={handleCloseModal} data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#modal_create_project">
                                <i className="ki-duotone ki-cross fs-1">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>
                            </div>
                        ) : addingHourFrom == 'approve_project' ? (
                            <div className="btn btn-sm btn-icon btn-active-color-primary" id="approveHoursCloseModalID" onClick={handleCloseModal} data-bs-dismiss="modal">
                                <i className="ki-duotone ki-cross fs-1">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>
                            </div>
                        ) : (
                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" id="defaultHoursCloseModalID">
                                <i className="ki-duotone ki-cross fs-1">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>
                            </div>
                        )}
                    </div>

                    {/*@ts-ignore*/}
                    <div className="modal-body scroll-y m-5">
                        {paymentIsSuccessful ? (
                            <div className={'d-flex flex-column align-items-center'}>
                                <div className="mb-7">
                                    <KTIcon iconName='check-circle' className='fs-5x me-5 text-primary' />
                                </div>
                                <div className="fw-semibold fs-3 mb-7">
                                    Success! Thank you for your payment.
                                </div>
                                <div className="mb-10">
                                    {addingHourFrom == 'project_form' ? (
                                        <button className='btn btn-sm btn-primary' data-bs-dismiss="modal" onClick={() => dispatch(setPaymentIsSuccessful(false))}>
                                            <span className='indicator-label'>Close</span>
                                        </button>
                                    ) : addingHourFrom == 'approve_project' ? (
                                        <button className='btn btn-sm btn-primary' data-bs-dismiss="modal" onClick={handleCloseSuccess}>
                                            <span className='indicator-label'>Close</span>
                                        </button>
                                    ) : (
                                        <button className='btn btn-sm btn-primary' onClick={handleCloseSuccess} data-bs-toggle="modal" data-bs-target="#modal_create_project" data-bs-dismiss="modal">
                                            <span className='indicator-label'>Close</span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <>
                                {priceIsLoading && (
                                    <div className="text-center">
                                        <span className="spinner-border align-middle text-primary"></span>
                                    </div>
                                )}
                                {priceError && (
                                    <div className="alert alert-danger d-flex align-items-center p-5 mb-10">
                                        <KTIcon iconName='cross' className='fs-2x me-3 svg-icon-danger' />

                                        <div className="d-flex flex-column">
                                            <h5 className="mb-1">Error</h5>
                                            <span>An error occurred while fetching the price. Please try again later.</span>
                                        </div>
                                    </div>
                                )}
                                {insufficientHour && (
                                    <div className="alert alert-warning d-flex align-items-center p-5">
                                        <i className="ki-duotone ki-shield-tick fs-2hx text-warning me-4"><span
                                            className="path1"></span><span className="path2"></span></i>
                                        <div className="d-flex flex-column">
                                            <h4 className="mb-1 text-dark">Insufficient hours</h4>
                                            <p>
                                                You don't have enough hours in your balance to validate or pay for this project.
                                                <br/>
                                                Please purchase more hour.
                                                <br/>
                                                <span className="text-black">Your current balance: {balance} hour</span>
                                            </p>
                                        </div>
                                    </div>
                                )}
                                {!showPaymentForm && price && (
                                    <>
                                        <Formik
                                            onSubmit={onSubmit}
                                            initialValues={{
                                                preset_quantity: '20',
                                                custom_quantity: '',
                                            }}
                                            validationSchema={object().shape({
                                                custom_amount: number().notRequired().min(1),
                                            })}
                                        >
                                            {({values, isSubmitting, setFieldValue}) => (
                                                <Form>
                                                    <QuantitySelection values={values} setFieldValue={setFieldValue}
                                                                       setQuantityToPay={setQuantityToPay}/>

                                                    <p className={'text-muted fs-5 mt-2'}>Hour Price = ${price?.unit_amount}/Hour</p>
                                                    <div className='fv-row mb-10 text-center'>
                                                        <button type='submit' className='btn btn-primary'>
                                                            {!isSubmitting && (
                                                                <span className='indicator-label'>
                                                        Pay {quantityToPay && `$${(quantityToPay * price.unit_amount).toFixed(2)}`}
                                                    </span>
                                                            )}
                                                            {(isSubmitting) && (
                                                                <span className='indicator-progress' style={{display: 'block'}}>
                                                        Please wait...
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                            )}
                                                        </button>
                                                    </div>
                                                    {paymentError && (
                                                        <div className="alert alert-danger p-2 mb-5">
                                                            <div className="d-flex flex-column">
                                                                <h5 className="mb-1">Error</h5>
                                                                <span>An error has occurred</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Form>
                                            )}
                                        </Formik>
                                    </>
                                )}
                                {showPaymentForm && (
                                    <div className={'d-flex flex-column text-center'}>
                                        <div className="d-flex align-items-start justify-content-center mb-7">
                                            <span className="fw-bold fs-4 mt-1 me-2">Hours</span>
                                            <span className="fw-bold fs-3x" id="create_project_budget_label">{quantityToPay}</span>
                                            <span className="fw-bold fs-3x">.00</span>
                                            <span className="text-muted fw-semibold fs-5 ms-2 mb-2 align-self-end">{quantityToPay && price && `for $${(quantityToPay * price.unit_amount).toFixed(2)}`}</span>
                                        </div>
                                    </div>
                                )}
                                {clientSecret && (
                                    <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
                                        <PaymentForm setClientSecret={setClientSecret} setVisible={setShowPaymentForm} />
                                    </Elements>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}