import {useEffect, useState} from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import {Link, useParams, useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import {getUserByToken, googleAuth, resetPassword} from '../core/_requests';
import {useDispatch} from "react-redux";
import {setPasswordReset} from "../../../store/AccountSlice";
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components';
import {useGoogleLogin} from "@react-oauth/google";
import {useAuth} from "../core/Auth";
import {toAbsoluteUrl} from "../../../../_metronic/helpers";

type ParamsProps = {
  token: string;
  email: string;
};

const initialValues = {
  password: '',
  repeatPassword: '',
}

const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
  repeatPassword: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
})

export function ResetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // @ts-ignore
  const {token, email, broker}: ParamsProps = useParams();
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('Sorry, looks like there are some errors detected, please try again.');
  const [googleLoading, setGoogleLoading] = useState(false);
  const [googleAuthError, setGoogleAuthError] = useState<string>();
  const [canUseGoogle, setCanUseGoogle] = useState<boolean>(false);
  const {saveAuth, setCurrentUser} = useAuth();
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setSubmitting}) => {
      setLoading(true);
      setHasErrors(false);
      resetPassword(token, email, values.password, values.repeatPassword, broker)
        .then(() => {
          setHasErrors(false);
          setLoading(false);
          setSubmitting(false);
          dispatch(setPasswordReset(true));
          navigate("/auth/login");
        })
        .catch((e) => {
          setHasErrors(true);
          setErrorMessage(e.response.data.message);
        })
        .finally(() => {
          setLoading(false);
          setSubmitting(false);
        })
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, []);

  useEffect(() => {
    if (!email) return;
    setCanUseGoogle(email.includes('@gmail'));
  }, [email]);

  const googleLogin = useGoogleLogin({
    hint: email,
    onSuccess: async (tokenResponse) => {
      setGoogleLoading(true);
      const auth = await googleAuth(tokenResponse.access_token)
        .then(response => response.data)
        .catch((error) => {
          if (error.response.status === 422) {
            setGoogleAuthError(error.response.data.message);
          } else {
            setGoogleAuthError('Fail to login with Google');
          }
          setGoogleLoading(false);
          return Promise.reject();
        });
      saveAuth(auth)
      const {data: user} = await getUserByToken(auth.token)
      setCurrentUser(user)
    },
    onError: errorResponse => {
      saveAuth(undefined)
      setGoogleAuthError('Fail to login with Google')
      setGoogleLoading(false)
    },
  });

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-10">
        <h1 className="text-dark fw-bolder mb-3">Create a New Password</h1>
        <div className="text-gray-500 fw-semibold fs-6">
          Do you already know your password? &nbsp;
          <Link to='/auth/login'>
            <span className="link-primary fw-bold">Sign in</span>
          </Link>
        </div>
      </div>

      {hasErrors && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            {errorMessage}
          </div>
        </div>
      )}
      {googleLoading ? (
        <div className="text-center">
          <span className="spinner-border align-middle"></span>
        </div>
      ) : (
        <>
          {(broker && canUseGoogle) && (
            <>
              <div className='row g-3 mb-9'>
                <div className='offset-md-3 col-md-6'>
                  <button
                    type={'button'}
                    className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
                    onClick={() => googleLogin()}
                  >
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                      className='h-15px me-3'
                    />
                    Sign in with Google
                  </button>
                </div>
              </div>

              {googleAuthError && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{googleAuthError}</div>
                </div>
              )}

              <div className='separator separator-content my-14'>
                <span className='w-125px text-gray-500 fw-semibold fs-7'>Or use password</span>
              </div>
            </>
          )}

          <div className='fv-row mb-8' data-kt-password-meter='true'>
            <div className='mb-1'>
              <label className='form-label fw-bolder text-dark fs-6'>Password</label>
              <div className='position-relative mb-3'>
                <input
                  type='password'
                  placeholder='Password'
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
              <div
                className='d-flex align-items-center mb-3'
                data-kt-password-meter-control='highlight'
              >
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
              </div>
            </div>
            <div className='text-muted'>
              Use 8 or more characters with a mix of letters, numbers & symbols.
            </div>
          </div>

          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
            <input
              type='password'
              placeholder='Password confirmation'
              autoComplete='off'
              {...formik.getFieldProps('repeatPassword')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.repeatPassword && formik.errors.repeatPassword,
                },
                {
                  'is-valid': formik.touched.repeatPassword && !formik.errors.repeatPassword,
                }
              )}
            />
            {formik.touched.repeatPassword && formik.errors.repeatPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.repeatPassword}</span>
                </div>
              </div>
            )}
          </div>

          <div className='d-grid mb-10'>
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-primary'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
              )}
            </button>
          </div>
        </>
      )}
    </form>
  )
}
